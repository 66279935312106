import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';

const dmcaPolicy = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle='DMCA Policy | BlueMail App' 
        postDescription='Everything new about Blue Mail - Redefining Your Email'
        postImage=''
        postURL='dmca-policy'
        postSEO
    />
    <div className="container pt-120 pb-100">
        <div style={{background: '#0B71BC', color: '#e6e6e6' , padding: '40px 0', marginBottom: '40px'}}>
            <div className='row center-text'>
                <div className='col-12'>
                <h1 style={{margin: '0', color: 'white'}}>DMCA Policy</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <p>
                We take the intellectual property rights of others seriously and require that our customers and users do the same. The Digital Millennium Copyright Act established a process for addressing claims of copyright infringement that we have implemented for BlueMail Services. If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that a third party is infringing that material on or through a BlueMail service, please send a notice to our copyright agent that includes all of the items below and we will expeditiously take appropriate action:
                </p>
                <p>
                A description of the copyrighted work that you claim is being infringed.
                </p>
                <p>
                A description of the material you claim is infringing and that you want removed or access to which you want disabled and the URL or other location of that material.
                </p>
                <p>
                Your address, telephone number, and email address.
                </p>
                <p>
                The following statement: “I have a good faith belief that the use of the copyrighted material I am complaining of is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)”.
                </p>
                <p>
                The following statement: “The information in this notice is accurate and, under penalty of perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly infringed”.
                </p>
                <p>
                An electronic or physical signature of the owner of the copyright or a person authorized to act on the owner’s behalf.
                </p>
                <p>
                Our designated copyright agent to receive such claims can be reached as follows: <a style={{color: '#1F6BF1'}} href="mailto:support@bluemail.me">support@bluemail.me</a>
                </p>
                <p>
                We may, in appropriate circumstances, disable or terminate the accounts of users who may be repeat infringers.
                </p>
                <p>
                This process does not limit our ability to pursue any other remedies we may have to address suspected infringement.
                </p>
            </div>
        </div>
    </div>
    <Footer />
    </Layout>
);

export default dmcaPolicy;

export const query = graphql`
query DmcaPolicyPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`